
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ClusterService from '@/modules/cluster/cluster.service';
import type ProvidersService from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class ProvidersRate extends Vue {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get value() {
        return this.clusterService.currentRatesProvider;
    }

    set value(value) {
        this.clusterService.saveCurrentProvider(value);
    }

    get items(): Item[] {
        const { rateProviders } = this.providersService.userProviders;
        return this.providersService.toItemsList(rateProviders);
    }
}
